import React from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import RequestDemoForm from "../../components/individualProductPage/RequestDemoForm"
import StealBackground from "../../components/individualProductPage/images/steal-background.jpg"
import SpecBarMachineImage from "../../components/individualProductPage/images/micromini-20d-profile.353.png"
import UsaBackground from "../../components/individualProductPage/images/handmade-in-usa-banner-micromini.jpg"
import MachineOverviewImage from "../../components/individualProductPage/images/micromini-machineoverview.jpg"
import HeavyDutyBackground from "../../components/individualProductPage/images/heavy-duty-banner-micromini.jpg"
import MachineDimensionImage from "../../components/individualProductPage/images/micromini-dims.jpg"
import YBTJWalkie from "../../../static/FC-WALKBEHIND-YBTJ.pdf"

//Thumbnail Images
import CylindricalThumb1Image from "../../components/individualProductPage/images/micromini-20d-360-view-1.jpg"
import CylindricalThumb2Image from "../../components/individualProductPage/images/micromini-20d-360-view-4.jpg"
import CylindricalThumb3Image from "../../components/individualProductPage/images/micromini-20d-360-view-3.jpg"
import CylindricalThumb4Image from "../../components/individualProductPage/images/micromini-20d-360-view-2.jpg"
import CylindricalThumb5Image from "../../components/individualProductPage/images/micromini-20d-360-view-5.jpg"
import DiskThumb1 from "../../components/individualProductPage/images/micromini-20d-360-view-1.jpg"
import DiskThumb2 from "../../components/individualProductPage/images/micromini-20d-360-view-4.jpg"
import DiskThumb3 from "../../components/individualProductPage/images/micromini-20d-360-view-3.jpg"
import DiskThumb4 from "../../components/individualProductPage/images/micromini-20d-360-view-2.jpg"
import DiskThumb5 from "../../components/individualProductPage/images/micromini-20d-360-view-5.jpg"
//End Thumbnail Images
import '../product-page-styles.scss';

//Main Images
import CylindricalMain1Image from "../../components/individualProductPage/images/micromini-20d-360-view-1.jpg"
import CylindricalMain2Image from "../../components/individualProductPage/images/micromini-20d-360-view-4.jpg"
import CylindricalMain3Image from "../../components/individualProductPage/images/micromini-20d-360-view-3.jpg"
import CylindricalMain4Image from "../../components/individualProductPage/images/micromini-20d-360-view-4.jpg"
import CylindricalMain5Image from "../../components/individualProductPage/images/micromini-20d-360-view-5.jpg"
import DiskMain1Image from "../../components/individualProductPage/images/micromini-20d-360-view-1.jpg"
import DiskMain2Image from "../../components/individualProductPage/images/micromini-20d-360-view-4.jpg"
import DiskMain3Image from "../../components/individualProductPage/images/micromini-20d-360-view-3.jpg"
import DiskMain4Image from "../../components/individualProductPage/images/micromini-20d-360-view-4.jpg"
import DiskMain5Image from "../../components/individualProductPage/images/micromini-20d-360-view-5.jpg"
import MediaTile from "../../components/individualProductPage/MediaTile"
import MainSpecBar from "../../components/individualProductPage/FeaturedProduct/MainSpecBar"
import MadeInUSA from "../../components/individualProductPage/MadeInUSA"
import SectionTitle from "../../components/common/SectionTitle"
import MachineOverview from "../../components/individualProductPage/MachineOverview"
import HeavyDuty from "../../components/individualProductPage/HeavyDuty"
import MachineDimensions from "../../components/individualProductPage/MachineDimensions"
import AnimateCylImage from "../../components/individualProductPage/images/disk-deck-loop-v2020.gif"

import { OptionsBar } from "../../components/individualProductPage/FeaturedProduct/FeaturedProductStyles/OptionsBar"
//End Main Images

import MicroMiniOptions from "../../components/individualProductPage/MicroMiniOptions"
import SEO from "../../components/SEO"
import Head from "../../components/Head"
import IndividualMachine from "../../components/individualProductPage/individualMachine"

const MediaTilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 840px) {
    max-width: 650px;
  }
`

const SizeBtnContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`

const MicroMiniProductPage = props => {
  return (
    <>
      <SEO title="MicroMini" />
      <Head title="MicroMini" />
      <Layout>
        <IndividualMachine
          displayV2="none"
          cylImage={AnimateCylImage}
          displayCyl="none"
          displayChoose="none"
          displayTitle="none"
          cylindricalThumb1={CylindricalThumb1Image}
          cylindricalThumb2={CylindricalThumb2Image}
          cylindricalThumb3={CylindricalThumb3Image}
          cylindricalThumb4={CylindricalThumb4Image}
          cylindricalThumb5={CylindricalThumb5Image}
          cylindricalMain1={CylindricalMain1Image}
          cylindricalMain2={CylindricalMain2Image}
          cylindricalMain3={CylindricalMain3Image}
          cylindricalMain4={CylindricalMain4Image}
          cylindricalMain5={CylindricalMain5Image}
          diskThumb1={DiskThumb1}
          diskThumb2={DiskThumb2}
          diskThumb3={DiskThumb3}
          diskThumb4={DiskThumb4}
          diskThumb5={DiskThumb5}
          diskMain1={DiskMain1Image}
          diskMain2={DiskMain2Image}
          diskMain3={DiskMain3Image}
          diskMain4={DiskMain4Image}
          diskMain5={DiskMain5Image}
          orbitalThumb1={DiskThumb1}
          orbitalThumb2={DiskThumb2}
          orbitalThumb3={DiskThumb3}
          orbitalThumb4={DiskThumb4}
          orbitalThumb5={DiskThumb5}
          orbitalMain1={DiskMain1Image}
          orbitalMain2={DiskMain2Image}
          orbitalMain3={DiskMain3Image}
          orbitalMain4={DiskMain4Image}
          orbitalMain5={DiskMain5Image}
          brochureLink="https://www.factorycat.com/docs/brochures/FC-COMPACTS-BRO-SM.pdf"
          techLink="https://www.factorycat.com/docs/techSpecs/FC-MICROMINI-SALESSHEET.pdf"
          partsLink="https://www.dropbox.com/s/xfbapwn8e919bna/MICROMINI-P-EN.pdf?dl=0"
          opLink="https://www.dropbox.com/s/00ysgqj35i2yoii/MICROMINI-OP-EN.pdf?dl=0"
          title="MicroMini Walk Behind Floor Scrubber"
          textContent="The MicroMini floor scrubber takes all the virtues of the MicroMini battery powered floor scrubber and adds more deck options on a different steel chassis.  The deck options offer a wider cleaning path and offer up to the edge cleaning.  The configuration results in a nimble, tough, and durable scrubber able to navigate tight quarters easily by any operator. Simple controls make running this scrubber easy for anyone. "
          mobileTitle="MicroMini Walk Behind Floor Scrubber"
          pageUrl=""
          specifications="Cleaning Path: 17-26 | Run Time: Up To 3.5 Hours | Tank Capacity: Sol: 10 Gal, Rec: 10 Gal"
          videoSource="https://www.youtube.com/embed/NaGoDJxcSGM"
          upLink="/walk-behind-floor-scrubbers/micro-hd"
          machineName="MicroMini"
          downLink="/walk-behind-floor-scrubbers/micromag"
          pmLink=""
          mtLink="https://www.dropbox.com/s/sx03160k2ppabfl/daily-weekly-maintenance-premium-v21306.pdf?dl=0"

        />

          <div className="tiles-container">
          <MediaTile
            title="Why FactoryCat ?"
            subTitle="Walk Behind Floor Scrubbers"
            link={YBTJWalkie}
          />
          <MediaTile
            title="Videos"
            subTitle="Walk Behind Floor Scrubbers"
            link="/videos"
          />
          <MediaTile
            link="/images#walkie-scrubbers"
            title="Images"
            subTitle="Walk Behind Floor Scrubbers"
          />
          <MediaTile
            title="Applications"
            subTitle="Walk Behind Floor Scrubbers"
            link="/applications"
          />
        </div>
        <MainSpecBar
          bgImage={StealBackground}
          machineImage={SpecBarMachineImage}
          myColor="white"
          machineName="MicroMini"
          category="Walk Behind Floor Scrubber"
          path='17 - 26"'
          path2="(43.2 - 66 cm)"
          runTime="Up To 3.5 Hours"
          solution="Solution: 10 Gal"
          solutionLiter="(37.6L)"
          recovery="Recovery: 10 Gal"
          recoveryLiter="(37.6L)"
        />
        <MadeInUSA bgImage={UsaBackground} />
        <div className="spec-title-wrapper">
        <SectionTitle title="Machine Overview" />
        </div>
        <MachineOverview image={MachineOverviewImage} />
        <HeavyDuty
          bgImage={HeavyDutyBackground}
          innerText="HEAVY DUTY CLEANING"
        />
        <MachineDimensions
          image={MachineDimensionImage}
          sizeTitle="Size (L x W x H):"
          size='42" x 21" x 39'
          weightTitle="Weight (w/o batteries):"
          weight="215 - 315 lbs"
          voltageTitle="System Voltage:"
          voltage="24 VDC"
          stdBatteryTitle="Standard Battery:"
          battery="130 ah WET"
          powerTitle="Power:"
          power="0.75hp"
          tankTitle="Tank Material:"
          tank='Poly 5/16"'
        />
        <OptionsBar>Options</OptionsBar>
        <MicroMiniOptions />
        <div id="demo-form"></div>
        <div className="spec-title-wrapper">
        <SectionTitle title="Request a Demo" />
        </div>
        <RequestDemoForm image={CylindricalMain1Image} />
      </Layout>
    </>
  )
}

export default MicroMiniProductPage
